import React, { useState } from 'react';
import styles from './Collapsible.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight,faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

/*
can be used controlled or uncontrolled (component uses its own state to know if being collapsed or not)
controlled props: collapsed, onChange
*/

const Collapsible = ({ open, closed, children, collapsed, onChange, i18n, 'data-testid':dataTestId }) => {
  const [_collapsed, setCollapsed] = useState(true);

  const collapsedVal = collapsed !== undefined ? collapsed : _collapsed;

  const dir = i18n.dir();
  const icon = collapsedVal ? (dir==='rtl' ? faChevronLeft : faChevronRight) : faChevronDown;

  return (
    <div className={styles.wrap}>
      <div 
        className={styles.header}
        onClick={() => { collapsed !== undefined ? onChange(!collapsedVal) : setCollapsed(!collapsedVal); }}
        data-testid={dataTestId}
      >
        <FontAwesomeIcon
          icon={icon}
          className={styles.headerIcon}
        />
        
        { collapsedVal
        ? closed
        : open
        }
      </div>

      <div
        className={classNames(
          styles.content,
          {[styles.hidden]: collapsedVal}
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default withTranslation()(Collapsible);