import React, { Ref, RefObject, useEffect, useRef, useState } from 'react';
import styles from './ClipCard.module.scss';
import defaultRadioKeyframe from '../../../assets/radio.jpg';
import defaultKeyframe from '../../../assets/defaultThumbnail.png';
import CountryFlag from 'src/Widgets/common/CountryFlag/CountryFlag';
import { highlightChannelName } from 'src/Widgets/common/MentionResults/MentionCard/MentionCard';
import useSentimentIcon from '../hooks/useSentimentIcon';
import { faClipboard, faCopy, faEye, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { MessageHandler } from 'src/API/MessageHandler/MessageHandler';
import { removeOrigin } from 'src/Widgets/common/helpers';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';

interface ClipCardProps {
    clip: any;
    clipDetailData: any;
    clipEditorialState?: any;
    clipStates: any;
    style: any;
    isEdited?: boolean;
    isEditorial?: boolean;
    isSearch?: boolean;
    activeFilter: any;
    isMentionFindrApp: boolean;
    backFillSelectionVisible: boolean;
    setSelectedCardElements: any;
    selectedCardElements?: any;
    updateClipState: any;
    basicNotifications?: any[];
    messageHandler?: MessageHandler;
    duplicates?: MentionsVisualizerTypes.ClipDetailType[];
    duplicateEditorialStates?: any;
}

const convertUnixTimestamp = (unixTimestamp: number, originalClipTimeStamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    const originalClipDate = new Date(originalClipTimeStamp * 1000);
    
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    const timeString = `${hours}:${minutes}:${seconds}`;

    const isOriginalClipDate = date.getDate() === originalClipDate.getDate() &&
                    date.getMonth() === originalClipDate.getMonth() &&
                    date.getFullYear() === originalClipDate.getFullYear();

    if (!isOriginalClipDate) {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year} ${timeString}`;
    }

    return timeString;
}

// TODO: change 'delete' to reopenReject (in api call)

const ClipCard = ({
    clip,
    clipDetailData,
    clipEditorialState,
    clipStates,
    style,
    isEdited,
    isEditorial,
    isSearch,
    activeFilter,
    isMentionFindrApp,
    backFillSelectionVisible,
    setSelectedCardElements,
    selectedCardElements,
    basicNotifications,
    duplicates,
    duplicateEditorialStates,
    updateClipState,
    messageHandler
}: ClipCardProps) => {
    //const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const programTitle =
    clipDetailData.program?.length > 50
        ? clipDetailData.program?.substring(0, 50) + '...'
        : clipDetailData.program;

const description =
    clipDetailData?.desc?.length > (isEdited ? 200 : 400)
        ? clipDetailData?.desc?.substring(0, isEdited ? 200 : 400) + '...'
        : clipDetailData?.desc;

    const isArabic = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(isEdited ? clip.summary : description);

    const meResponse = useAppSelector((state) => state.me);
    // const messageHandlerRef = useRef(new MessageHandler());

    const sentimentIcon = useSentimentIcon(clip.sentiment, isArabic);
    const { t } = useTranslation();

    // const copyToClipboard = (dataToCopy: any) => {
    //     navigator.clipboard.writeText(dataToCopy);
    //     setShowCopiedMessage(true);
    //     setTimeout(() => {
    //         setShowCopiedMessage(false);
    //     }, 1000);
    // };

    const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const isChecked = (e.target as HTMLInputElement).checked;
        if (isChecked) {
            setSelectedCardElements([...selectedCardElements, clipDetailData]);
        } else {
            setSelectedCardElements(
                selectedCardElements.filter((item: any) =>
                    item.notifID
                        ? item.notifID !== clipDetailData.notifid
                        : item.notifid !== clipDetailData.notifid
                )
            );
        }
    };

    useEffect(() => {
        const handleMouseDown = (e: any) => {
            if (e.shiftKey) {
                e.preventDefault();
            }
        };

        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    const handleDuplicateClick = async (e: any, notifID: number, viewLink: string) => {
        e.stopPropagation();
        const duplicateClip = duplicates?.find(
            (clip: MentionsVisualizerTypes.ClipDetailType) => clip.notifid === notifID
        );
        const clipState = duplicateEditorialStates?.find(
            (state: any) => Number(state.id) === Number(duplicateClip?.notifid)
        );

        if (process.env.REACT_APP_ENV === 'development') {
            viewLink = removeOrigin(viewLink);
        }

        const url = isEditorial
            ? `${viewLink + '&ed=2'}`
            : viewLink;

        
        const handleCtrShiftClick = (e: any) => {
            // Check for Shift and Ctrl keys and only perform the state update

            if (e.shiftKey) {
                if(clipState.state === 'locked' && meResponse?.name === clipState.userid || ['rejected', null].includes(clipState.state) ){
                    // Set state to "duplicate"
                    updateClipState(duplicateClip?.notifid, clipState.cas, clipStates.cl, 'dup');
                } else if (clipState.state === 'dup') {
                    updateClipState(duplicateClip?.notifid, clipState.cas, clipStates.cl, 'giveback');
                }
            } else if (e.ctrlKey) {
                if(clipState.state === 'locked' && meResponse?.name === clipState.userid || ['dup', null].includes(clipState.state)){
                    // Set state to "reject"
                    updateClipState(duplicateClip?.notifid, clipState.cas, clipStates.cl, 'reject');
                } else if (clipState.state === 'rejected') {
                    updateClipState(duplicateClip?.notifid, clipState.cas, clipStates.cl, 'giveback');
                }
            }
        }

        if (isEditorial) {
            if(e.shiftKey || e.ctrlKey){
                handleCtrShiftClick(e);
                return;
            }
            if (clipState.state === null) {
                    await updateClipState(duplicateClip?.notifid, clipState.cas, clipStates.cl, 'take');
            }
           
        }

        const meWindow = window.open(url, 'mediaEditor');

            if (isEditorial) {
                console.log('currWindow',meWindow, meWindow?.name)
                messageHandler?.setWindow(meWindow);
            }

    }

    const handleCardClick = async (e: any) => {

        if (backFillSelectionVisible) {
            const isChecked = selectedCardElements.some((item: any) =>
                item.notifID
                    ? item.notifID === clipDetailData.notifid
                    : item.notifid === clipDetailData.notifid
            );
            setSelectedCardElements(
                isChecked
                    ? selectedCardElements.filter((item: any) =>
                          item.notifID
                              ? item.notifID !== clipDetailData.notifid
                              : item.notifid !== clipDetailData.notifid
                      )
                    : [...selectedCardElements, clipDetailData]
            );
        } else {
            if (process.env.REACT_APP_ENV === 'development') {
                clipDetailData.viewLink = removeOrigin(clipDetailData.viewLink);
            }

            const url = isEditorial
                ? `${clipDetailData.viewLink + '&ed=2'}`
                : clipDetailData.viewLink;
            
            const handleCtrShiftClick = (e: any) => {
                // Check for Shift and Ctrl keys and only perform the state update

                if (e.shiftKey) {
                    if(clipEditorialState.state === 'locked' && meResponse?.name === clipEditorialState.userid || ['rejected', null].includes(clipEditorialState.state) ){
                        // Set state to "duplicate"
                        updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, 'dup');
                    } else if (clipEditorialState.state === 'dup') {
                        updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, 'giveback');
                    }
                } else if (e.ctrlKey) {
                    if(clipEditorialState.state === 'locked' && meResponse?.name === clipEditorialState.userid || ['dup', null].includes(clipEditorialState.state)){
                        // Set state to "reject"
                        updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, 'reject');
                    } else if (clipEditorialState.state === 'rejected') {
                        updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, 'giveback');
                    }
                }
            }
    
            if (isEditorial) {
                if(e.shiftKey || e.ctrlKey){
                    handleCtrShiftClick(e);
                    return;
                }
                if (clipEditorialState.state === null) {
                        await updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, 'take');
                }
                
            }

            const meWindow = window.open(url, 'mediaEditor');

            if (isEditorial) {
                console.log('currWindow',meWindow, meWindow?.name)
                messageHandler?.setWindow(meWindow);
            }
        }
    };
    
    //Editorial Workflow Color Conditions
    const editorialContainerColor = (clipEditorialState: any) => {
        if(clipEditorialState){
            switch(clipEditorialState.state) {
                case 'locked':
                    if(clipEditorialState.userid !== meResponse?.username){
                        return 'rgb(255, 255, 200)';
                    }
                    return 'rgb(255, 255, 150)';
                case 'done':
                    return 'rgb(200, 255, 200)';
                case 'dup':
                    return 'rgb(221, 221, 221)';
                case 'rejected':
                    return 'rgb(170, 170, 170)';
                default:
                    return;
            }
        }
    }

    const mergedBasicNotifications = clip.basicNotifications?.map((clipNotif: any) => {
        const matchingNotif = basicNotifications?.find(
            (notif: any) => notif.notifid === clipNotif.notifID
        );
        return matchingNotif ? { ...clipNotif, ...matchingNotif } : clipNotif;
    });

    const mergedDuplicates = clip.duplicates?.map((clipNotif: any) => {
        const matchingNotif = duplicates?.find(
            (notif: any) => notif.notifid === clipNotif.notifID
        );
        return matchingNotif ? { ...clipNotif, ...matchingNotif } : clipNotif;
    });

    const formatDuplicate = (originalClip: MentionsVisualizerTypes.Clip, duplicates: MentionsVisualizerTypes.Clip[]) => {
        const groupedByChannel: any = {};
        const clipsData: any[] = [];  // Temporary array to hold time difference data for sorting
        let hasAlsoSeenAt = false;
    
        duplicates.forEach((duplicate: MentionsVisualizerTypes.Clip) => {
            if (duplicate.dupType === 'clip') {
                // Calculate and store the time difference output for each duplicate clip
                const timeDifference = calculateTimeDifference(originalClip.hitTime, duplicate.hitTime);
                clipsData.push({
                    timeDifference,
                    notifID: duplicate.notifID,
                    desc: duplicate.desc,
                    viewLink: duplicate.viewLink,
                });
            } else if (duplicate.dupType === 'channel' || duplicate.dupType === '*' || duplicate.dupType === 'network') {
                // Group channel duplicates by channelId and collect appearance times
                hasAlsoSeenAt = true;
                const time = convertUnixTimestamp(duplicate.hitTimeTS, originalClip.hitTimeTS); // Format the timestamp
                if (!groupedByChannel[duplicate.channelNumber]) {
                    groupedByChannel[duplicate.channelNumber] = {
                        channelName: duplicate.channelName,
                        times: [{
                            timeCode: time,
                            notifID: duplicate.notifID,
                            viewLink: duplicate.viewLink,
                        }],
                    };
                } else {
                    groupedByChannel[duplicate.channelNumber].times.push({
                        timeCode: time,
                        notifID: duplicate.notifID,
                        viewLink: duplicate.viewLink,
                    });
                }
            }
        });
    
        // Sort clipsData by timeDifference in ascending order
        clipsData.sort((a, b) => a.timeDifference - b.timeDifference);
    
        // Map sorted clipsData to clipsOutput JSX elements
        const clipsOutput = clipsData.map(({ timeDifference, notifID, desc, viewLink }) => (
            <div key={notifID} className={styles.xSecsLater} title={isEditorial ?
                showStateHoverMessage(
                    duplicateEditorialStates?.find(
                        (state: any) => Number(state.id) === Number(notifID)
                )) : ''
            } style={
                isEditorial
                ? {
                    backgroundColor: editorialContainerColor(
                        duplicateEditorialStates?.find(
                            (state: any) => Number(state.id) === Number(notifID)
                        )
                  ),
                      borderRadius: '0.5rem',
                      padding: '0.1rem',
                     boxSizing: 'border-box'
                  }
                : {}
            } onClick={
                (e) => {
                    handleDuplicateClick(e, notifID, viewLink);
                }
            }>
                <span className={styles.timeDifference}>[{timeDifference}s later]</span> - <span dangerouslySetInnerHTML={{ __html: desc }} />
            </div>
        ));
    
        const prefix = !hasAlsoSeenAt ? '' : t('Also seen at') + ': ';
    
        // Render both clips output and grouped channel appearances
        return (
            <div className={styles.clipsOutputContainer}>
                <span className={styles.seenPrefix}>{prefix}</span>
                {clipsOutput}
                <div className={styles.asSeenAt}>
                {/* Render grouped channel duplicates */}
                {Object.values(groupedByChannel).map((channelData: any) => (
                       <>
                        {channelData.channelName !== originalClip.channelName && (
                            <span className={styles.channelNameAs}>{channelData.channelName}</span>
                        )}
                        {channelData.times
                        .sort((a: any, b: any) => a.timeCode.localeCompare(b.timeCode))
                        .map((item: any, index: number) => (
                            <span
                                onClick={(e) => {
                                    handleDuplicateClick(e, item.notifID, item.viewLink);
                                }}
                                key={item.notifID}
                                title={
                                    isEditorial ?
                                    showStateHoverMessage(
                                        duplicateEditorialStates?.find(
                                            (state: any) => Number(state.id) === Number(item.notifID)
                                        )
                                    ) : ''
                                }
                                style={
                                    isEditorial
                                    ? {
                                              backgroundColor: editorialContainerColor(
                                                duplicateEditorialStates?.find(
                                                    (state: any) => Number(state.id) === Number(item.notifID)
                                                )
                                          ),
                                              borderRadius: '0.5rem',
                                              padding: '0.1rem',
                                             boxSizing: 'border-box'
                                      }
                                    : {}
                                }
                                className={`${styles.asSeenTime} time-${item.notifID}`}
                            >
                                {item.timeCode}
                                {index < channelData.times.length - 1 && ', '}
                            </span>
                            
                        ))}

                    </>
                ))}
                </div>
            </div>
        );
    };
    

      // Function to calculate time difference in seconds
      const calculateTimeDifference = (originalTime: number | string, duplicateTime: number | string) => {
        const originalDate = new Date(originalTime);
        const duplicateDate = new Date(duplicateTime);
        const timeDifference = Math.abs((duplicateDate.getTime() - originalDate.getTime()) / 1000);
        return Math.round(timeDifference);
      };

        const isDuplicate = (clip: MentionsVisualizerTypes.Clip) => {
            return clip.dupType !== null
        }

    const showStateHoverMessage = (clipEditorialState: any) => {
        return clipEditorialState?.state 
            ? `${clipEditorialState?.state} by ${clipEditorialState?.userid} on ${clipEditorialState?.usertime}` 
            : `${t('CTRL + Click = reject, SHIFT + Click = duplicate')}`;
    }

    //const displayedTitles = new Set<string>();

    return (
        <div
            style={
                isEditorial
                    ? {
                          ...style,
                          borderLeft:
                              'solid 15px' +
                              ' ' +
                              editorialContainerColor(clipEditorialState)
                      }
                    : style
            }
            key={clip.channelid}
            className={isArabic ? styles.clipCardContainerRTL : styles.clipCardContainer}
            onClick={handleCardClick}
            title={isEditorial ? showStateHoverMessage(clipEditorialState) : ''}
            data-testid="clip-card"
        >
            {backFillSelectionVisible && (
                <input
                    type="checkbox"
                    className={isArabic ? styles.backFillCheckboxRTL : styles.backFillCheckbox}
                    checked={selectedCardElements.some((item: any) =>
                        item.notifID
                            ? item.notifID === clipDetailData.notifid
                            : item.notifid === clipDetailData.notifid
                    )}
                    onClick={handleCheckboxClick}
                    onChange={(e) => e.stopPropagation()}
                />
            )}
            {isDuplicate(clip) && (
                <div
                    title={t('This is a duplicate clip')}
                    className={`${styles.duplicateIcon} ${
                        isArabic ? styles.duplicateIconRTL : ''
                    }`}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </div>
            )}
            <img
                width={5}
                src={
                    clipDetailData.mediatype === 'radio'
                        ? defaultRadioKeyframe
                        : clipDetailData.kfLink
                }
                alt="clip thumbnail"
                onError={(e: any) => {
                    e.target.src = defaultKeyframe;
                }}
                className={styles.clipKeyframe}
            />
            <FontAwesomeIcon
                icon={faPlay}
                className={
                    backFillSelectionVisible ? (isArabic ? styles.playIconBackFillRTL : styles.playIconBackFill) : (isArabic ? styles.playIconRTL : styles.playIcon)
                }
                // onClick={(e) => {
                //     e.stopPropagation();
                //     window.open(clipDetailData.viewLink, '_blank');
                // }}
            />
            <div className={isArabic ? styles.clipTitleContainerRTL : styles.clipTitleContainer}>
                {isEdited && sentimentIcon && (
                    <span className={styles.sentimentIcon}>{sentimentIcon}</span>
                )}

                {

                        activeFilter.profile === null && !isSearch && (
                            <div
                                className={isArabic ? styles.profileTitleRTL : styles.profileTitle}
                                data-full-title={clip.profileTitle}
                            >
                                {clip.profileTitle.length > 17
                                    ? `${clip.profileTitle.substring(0, 17)}...`
                                    : clip.profileTitle}
                            </div>
                        )
                       

                }
                {/* <hr className={styles.cardHr}/> */}
                <span
                    className={styles.clipTitle}
                    style={isMentionFindrApp ? { fontSize: '0.8rem' } : {}}
                >
                    <div className={styles.timeCodeChannel}>
                        <img
                            src={clipDetailData.icon}
                            width="40"
                            height="20"
                            alt="icon"
                        />
                        <span className={styles.channelName}>
                            {highlightChannelName(
                                clip.channelName,
                                clipDetailData.channel
                            )}
                        </span>
                        <span>{clipDetailData?.beginF}</span>
                        {clip.headline && ' - '}
                        {programTitle && ' - '}
                        <span className={styles.programTitle}>
                            {programTitle}
                            {programTitle && clip.summary && ' - '}
                            {isEdited && (
                                <div
                                    className={styles.summaryHeadlineContainer}
                                    title={clip.headline}
                                >
                                    <span className={styles.editedHeadline}>
                                        {clip.headline
                                            ? clip.headline.slice(0, 95) +
                                              (clip.headline.length > 95 ? '...' : '')
                                            : ''}
                                    </span>
                                    {/* {programTitle && clip.summary && clip.headline && ' - '} */}
                                </div>
                            )}
                        </span>
                        {meResponse.can_AVE === '1' && clipDetailData.aveReach && (
                            <span className={styles.ave}>
                                {` ${clipDetailData.clipDuration} sec | ${Math.trunc(
                                    clipDetailData.clipDuration * clipDetailData.aveRate
                                )} ${clipDetailData.aveCurrency || ''} | ${Math.trunc(
                                    clipDetailData.aveReach
                                )}`}{' '}
                                <FontAwesomeIcon icon={faEye} />
                            </span>
                        )}
                    </div>
                    <div className={styles.flagContainer}>
                        <CountryFlag cc={clip.cc} width={20} title={clip.country} />
                    </div>
                </span>
                {isEdited && (
                    <div className={styles.summaryHeadlineContainer}>
                        <span className={styles.editedSummary} title={clip.summary}>
                            {clip.summary
                                ? clip.summary.slice(0, 400) +
                                  (clip.summary.length > 400 ? '...' : '')
                                : null}
                        </span>
                    </div>
                )}

                {/* {isEdited && <hr className={styles.cardHr} />} */}

                {!isEdited && (
                    <span
                        className={isArabic ? styles.clipDescArabic : styles.clipDesc}
                        title={clipDetailData.desc}
                        dangerouslySetInnerHTML={{
                            __html: description
                        }}
                    ></span>
                )}
                {/* {showCopiedMessage && (
                    <div className={styles.copiedMessage}>
                        <span>
                            {isEdited ? 'Summary' : 'Description'}{' '}
                            {t('copied to clipboard')}
                        </span>
                    </div>
                )} */}
                {mergedDuplicates &&
                    mergedDuplicates.length > 0 &&
                    activeFilter.foldEnabled && (
                        <div
                            className={styles.duplicatesContainer}
                        >
                            {formatDuplicate(clip, mergedDuplicates)}
                        </div>
                    )}
                {mergedBasicNotifications && (
                    <div
                        className={styles.basicNotificationsContainer}
                        //onClick={(e: any) => e.stopPropagation()}
                    >
                        {Object.entries(
                            mergedBasicNotifications.reduce(
                                (acc: any, notification: any) => {
                                    const title =
                                        notification.queries?.find(
                                            (query: any) => query.id === notification.qid
                                        )?.title || '';
                                    if (!acc[title]) {
                                        acc[title] = [];
                                    }
                                    acc[title].push(notification);
                                    return acc;
                                },
                                {}
                            )
                        )
                            .sort(([titleA], [titleB]) => titleA.localeCompare(titleB))
                            .map(([title, notifications]: any) => (
                                <div
                                    key={title}
                                    className={`${styles.basicNotificationCard}`}
                                >
                                    <p className={styles.superTimesContainer}>
                                        <span
                                            className={styles.superTimeStamp}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <span className={styles.queryTitle}>
                                                {title || '0'} ({notifications.length})
                                            </span>
                                        </span>
                                    </p>
                                    {notifications.map((notification: any, index: number) => (
                                        <div key={notification.notifID}>
                                            <span className={styles.superTimesContainer}>
                                                <span
                                                    className={styles.superTimeStamp}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    {convertUnixTimestamp(
                                                        notification.hitTimeTS,
                                                        clip.hitTimeTS
                                                    )}
                                                </span>
                                                {index < notifications.length - 1 && ', '}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
}
export default ClipCard;
