import React, { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
// @ts-ignore
import { NavLink } from 'react-router-dom';
// @ts-ignore
import _ from 'lodash';

import '../../assets/animate.css';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleEditButton from './ToggleEditButton/ToggleEditButton';
import { logo } from 'src/assets/themes/themes';
import Button from 'src/Widgets/common/basicElements/Button/Button';
// @ts-ignore
import styles from './Navbar.module.css';

// translating HOC
import { useTranslation } from 'react-i18next';
import { setShowAcademy, setLoadingGlobal } from 'src/redux/actions/actions.board';
import { getWorkspaces } from 'src/redux/actions/actions.workspaces';
import { urlParamsToObj } from 'src/Widgets/common/helpers';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';
import emmGlobe from 'src/assets/emm-circle-2407.png';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import Sortable from 'src/Widgets/common/DragDrop/Sortable';

interface NavbarProps{
    className: string;
    toggleDisplayMenuPortrait: MouseEventHandler<HTMLDivElement>;
    workspaces: {name: string, icon: any, uiPosition: number}[];
    addWorkspace: (e?: MouseEvent) => void;
    removeWorkspace: Function;
    setWorkspaceUIPosition: (workspaceIdx:number, uiPosition:number) => void;
    toggleBoardEdit: Function;
    editable: boolean;
    currentWorkspace: number;
    location: any;
    isDesktop: boolean;
}

const Navbar = ({ className,toggleDisplayMenuPortrait, workspaces,addWorkspace,removeWorkspace,toggleBoardEdit,editable,currentWorkspace,location,isDesktop, setWorkspaceUIPosition}:NavbarProps) => {
    const { t } = useTranslation();
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);

    const dispatch = useAppDispatch();
    const theme = useAppSelector((state) => state.theme);
    const board = useAppSelector((state) => state.board);
    const me = useAppSelector((state) => state.me);
    const accountSettings = useAppSelector((state) => state.accountSettings);

    const params:any = urlParamsToObj(window.location?.search, ['academy']);


    const toggleNav = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    useEffect(() => {
      window.dispatchEvent(new Event('resize'));
    }, [isNavCollapsed]);

    const renderWorkspaceLink = (workspaceName:string, i:number) => {
        let active = false;

        if (location.pathname.includes('workspaces')) {
            active = currentWorkspace === i;
        }

        const linkClasses = [
            isNavCollapsed ? 'workspace-link-collapsed' : 'workspace-link',
            // TODO maybe: use this again: 'animated', 'bounceIn' (but avoid bouncing on page load)
            active ? 'workspace-link-active' : '',
            active ? theme.borderSecondary : '',
        ].join(' ');

        return (
            <div
                key={i + '-container'}
                className={linkClasses}
                data-tut={`tour__workspace-${i + 1}`}
            >
                <span
                    className={
                        isNavCollapsed
                            ? 'remove-workspace-collapsed' +
                              (editable
                                  ? ' animated bounceIn'
                                  : ' remove-workspace-hidden')
                            : 'remove-workspace' +
                              (editable
                                  ? ' animated bounceIn'
                                  : ' remove-workspace-hidden')
                    }
                    onClick={() => removeWorkspace(i)}
                >
                    <FontAwesomeIcon
                        icon="times-circle"
                        data-testid={'remove-report-' + (i + 1)}
                        size={isNavCollapsed ? 'xs' : '1x'}
                    />
                </span>

                <NavLink
                    key={i}
                    className={
                        isNavCollapsed
                            ? 'navbar-link-collapsed'
                            : 'navbar-link'
                    }
                    to={'/workspaces/' + (i + 1)}
                    data-testid={(i + 1 )}
                >
                    <div className={isNavCollapsed ? '' : "navLinkWithIconContainer"}>
                        <FontAwesomeIcon
                            icon={workspaces[i].icon}
                            size={isNavCollapsed ? '2x' : '1x'}
                        />
                        <div
                            className={
                                isNavCollapsed ? 'navLinkTextCollapsed' : ''
                            }
                        >
                            {/* {this.state.isNavCollapsed
                                ? workspaceName
                                      .split(' ')
                                      .map((word, index, arr) =>
                                          arr.length === 1 ? word.slice(0, 2) : word[0]
                                      )
                                      .join('')
                                : workspaceName || this.props.t('Unnamed')} */}
                                {isNavCollapsed ? null : workspaceName || t('Unnamed')}
                        </div>
                    </div>
                </NavLink>
            </div>
        );
    }

    const navClasses = [
        className,
        'nav',
        theme.backgroundPrimary
    ].join(' ');

    const renderWorkspaceLinks = () => {
        return (
            // render navlinks depending on existing workspaces
            _.map(workspaces, (workspace: NavbarProps['workspaces'][number], i:number) =>
                renderWorkspaceLink(workspace.name, i)
            )
        )
    }

    const renderWorkspaceLinksSortable = () => {
        return (
            // @ts-ignore
            <Sortable
            isDraggable={true}
            lists={1}
                renderList={(listIndex: number, renderItem: any) => {
                    return (
                        <div key={listIndex}>
                            {/* render navlinks depending on existing workspaces */}
                            { _.map(workspaces, (workspace:NavbarProps['workspaces'][number], workspaceIndex:number) =>
                                renderItem({uiPosition: workspace.uiPosition}, workspaceIndex, listIndex, () => renderWorkspaceLink(workspace.name, workspaceIndex))
                            )}
                        </div>
                    )
            }}
                onDrop={(obj: any) => {
                    setWorkspaceUIPosition(obj.drag.item.uiPosition, obj.drop.item.uiPosition);
            }}
        ></Sortable>
        )
    }
    
    return (
        <React.Fragment>
            <nav
                className={[
                    className,
                    theme.backgroundPrimary,
                    navClasses,
                    isNavCollapsed ? 'nav-collapsed' : 'nav'
                ].join(' ')}
                data-tut="tour__navBar"
            >
                <div
                    className={
                        isNavCollapsed
                            ? 'app-title-collapsed'
                            : 'app-title'
                    }
                >
                    <img
                        src={isNavCollapsed ? emmGlobe : logo}
                        alt="eMedia Monitor"
                        className={
                            isNavCollapsed
                                ? 'collapsedLogo'
                                : 'nonCollapsedLogo'
                        }
                    />
                </div>

                {
                    // only render when editable
                    editable ? (
                        <Button
                            type="addWorkspace"
                            btnClass={
                                isNavCollapsed
                                    ? 'add-workspace-collapsed'
                                    : 'add-workspace'
                            }
                            onClick={addWorkspace}
                        >
                            {t('Add Workspace')}
                        </Button>
                    ) : null
                }

                <div
                    className={
                        'workspace-links' +
                        (editable ? ' workspace-links-editable' : '')
                    }
                >
                    <FontAwesomeIcon
                        style={{
                            cursor: 'pointer'
                        }}
                        title={
                            t('Collapse/Expand Navigation')
                        }
                        icon={
                            document.documentElement.getAttribute('dir') === 'rtl' ?
                            (
                                isNavCollapsed ? faAngleDoubleLeft : faAngleDoubleRight
                            ) :  
                            (
                                isNavCollapsed ? faAngleDoubleRight : faAngleDoubleLeft
                            )
                        }
                        onClick={toggleNav}
                        size={isNavCollapsed ? 'lg' : 'lg'}
                        className={
                            document.documentElement.getAttribute('dir') === 'rtl' ?
                            (
                                isNavCollapsed
                                    ? 'collapse-nav-icon-collapsed-rtl'
                                    : 'collapse-nav-icon-rtl'
                            ) :  
                            (
                                isNavCollapsed
                                    ? 'collapse-nav-icon-collapsed'
                                    : 'collapse-nav-icon'
                            )
                        }
                    />
                    {isNavCollapsed ? <hr
                        style={
                            isNavCollapsed
                                ? { width: '2rem', display: 'flex' }
                                : {}
                        }
                    /> : null}
                    {editable
                        ? renderWorkspaceLinksSortable()
                        : renderWorkspaceLinks()
                    }
                </div>

                {isDesktop && dynamicConfig.allowEditBoard ? (
                    <div
                        className={
                            isNavCollapsed
                                ? styles.editBoardCollapsed
                                : styles.editBoard
                        }
                    >
                        <hr
                            style={
                                isNavCollapsed
                                    ? { width: '2rem', display: 'flex' }
                                    : {}
                            }
                        />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: isNavCollapsed
                                    ? 'column'
                                    : 'row'
                            }}
                        >
                            {(me.isAcademyAdmin ||
                                !board.showAcademy) && ( // in academy mode: show for 'admin' only
                                <ToggleEditButton
                                    editable={editable}
                                    onClick={toggleBoardEdit}
                                    isNavCollapsed={isNavCollapsed}
                                />
                            )}
                        </div>
                    </div>
                ) : null}
                {(accountSettings.data.academyEnabled ||
                    params.academy === '1') &&
                    dynamicConfig.allowAcademy && (
                        <div
                            className={
                                isNavCollapsed
                                    ? styles.toggleAcademyCollapsed
                                    : styles.toggleAcademy
                            }
                        >
                            <Button
                                type="secondary"
                                btnClass={
                                    isNavCollapsed
                                        ? styles.academyButtonCollapsed
                                        : styles.academyButton
                                }
                                onClick={async () => {
                                    dispatch(setLoadingGlobal(true));
                                    await dispatch(setShowAcademy(
                                        !board.showAcademy
                                    ));
                                    dispatch(getWorkspaces());
                                }}
                            >
                                {board.showAcademy
                                    ? t('Go To Dart')
                                    : t('Go To Academy')}
                            </Button>
                        </div>
                    )}
            </nav>

            {/* 
                only render when .display is set
                and because of css @media query only when in portrait mode 

                (invisible) div to toggle nav in portrait view
            */}
            {className ? (
                <div
                    className="nav-away animated fadeIn"
                    onClick={toggleDisplayMenuPortrait}
                />
            ) : null}
        </React.Fragment>
    );
}

export default Navbar;