import { faCaretDown, faCaretUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import styles from './TableRow.module.scss';

import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import { TableRowProps } from './TableRow.types';
import { ChannelGroup } from '../GroupCardList/GroupCardList.types';
import { ChannelLessData } from '../../shared/GroupCardSingleItem/GroupCardSingleItem.types';
import { useSelector } from 'react-redux';
import { Channel } from 'src/@types/shared.types';
import config from 'src/config/config';

const TableRow: FC<TableRowProps> = ({
  item,
  index,
  tableHeaders,
  checkedItems,
  handleCheckboxChange,
  isMobile,
  handleShowToggle,
  expandedRows
}) => {
  const [hoveredName, setHoveredName] = useState('');
  const [hoverData, setHoverData] = useState([]);
  const [showInfoBox, setShowInfoBox] = useState(false);

  const { t, i18n } = useTranslation();
  const translatedHeaders = tableHeaders?.map((translatedHeader: string) =>
    t(translatedHeader)
  );

  const allChannels = useAppSelector((state) => state?.channelReducers)

  const channelGroupsWithChannels = useAppSelector(
    (state) => state.channelGroupReducers
  );
  const memorizedChannelGroups = useMemo(
    () => channelGroupsWithChannels,
    [channelGroupsWithChannels]
  );

  const findChannelId = (channelName: string) => {
    const channelId = allChannels?.data.find((channel: Channel) => channel.name === channelName)?.id;
    return channelId;
  }

  // Hover icon functions
  const hoverFunction = (e: SyntheticEvent, selectedChannelName: string) => {
      const channelGroupsWithoutTemp = memorizedChannelGroups.filter((channelGroup: ChannelGroup) => !channelGroup.title.includes(config.searchChannelGroupTitle))
      const filteredChannelGroups = channelGroupsWithoutTemp?.filter(
          (channelGroup: ChannelGroup) => {
              return channelGroup?.channels?.some((channel: ChannelLessData) => {
                  return channel?.id === findChannelId(selectedChannelName);
              });
          }
      );
      setHoverData(filteredChannelGroups);
      setHoveredName(selectedChannelName);
      setShowInfoBox(true);
  };

  const hoverCondition = (channelName: string) => {
    return (
      showInfoBox &&
      channelName === hoveredName && (
        <div className={styles.hoverInfoBox} data-testid="hover-content">
          <span>{t("Included In")}</span>
          {hoverData?.length ? (
            hoverData?.map((group: ChannelGroup) => {
              return (
                <span className={styles.hoveredGroups} key={group?.id}>
                  {group?.title}
                </span>
              );
            })
          ) : (
            <span>{t("No Groups Included")}</span>
          )}
        </div>
      )
    );
  };

  const titleConditional = () => {
    if (item?.isUnique) {
      return t('No access to channel');
    } else if (item?.isNotAvailable) {
      return t('Not available channel');
    }
  };

  const iconOnViewsConditional = (header: string) => {
    if (isMobile && !expandedRows[index]) {
      return (
        <div className={styles.arrowColumnOnMobile} onClick={(e) => handleShowToggle(index, e)}>
        <FontAwesomeIcon
          
          icon={faCaretDown}
          size="lg"
        />
        </div>
      );
    } else if (isMobile && expandedRows[index]) {
      return (
        <div className={styles.arrowColumnOnMobile} onClick={(e) => handleShowToggle(index, e)}>
        <FontAwesomeIcon
          
          icon={faCaretUp}
          size="lg"
        />
        </div>
      );
    } else {
      return (
        <Checkbox withBorder={true} checked={checkedItems[item[header]] || false} />
      );
    }
  };

  //In case of screen size change, collapse row heights
  useEffect(() => {
    !isMobile && expandedRows[index] && handleShowToggle(index)
  },[isMobile])

  return (
      <div
          className={`${styles.bodyRow} ${index % 2 === 0 ? styles.oddRow : ''} ${
              i18n.dir() === 'rtl' ? styles.rtl : ''
          }`}
          style={item?.isUnique || item?.isNotAvailable ? { opacity: 0.4 } : {}}
          key={index}
          title={titleConditional()}
          data-testid="table-row"
          onClick={() => {
              const name = t('Name');
              if (
                  (typeof item[name] === 'string' || item[name] === undefined) &&
                  typeof item.Id === 'number'
              ) {
                  handleCheckboxChange(
                      !(checkedItems[item[name]] || false),
                      item[name] as string | undefined,
                      item.Id
                  );
              }
          }}
      >
          {translatedHeaders?.map((header, headerIndex) => {
              if (translatedHeaders[0] === header) {
                  return (
                      <div
                          className={`${styles.firstRowContainer} ${
                              i18n.dir() === 'rtl' ? styles.rtl : ''
                          }`}
                          key={headerIndex}
                      >
                          <div
                              className={`${styles.firstRowElements} ${
                                  i18n.dir() === 'rtl' ? styles.rtl : ''
                              }`}
                          >
                              {iconOnViewsConditional(header)}
                              {isMobile && (
                                  <>
                                      <Checkbox
                                          withBorder={true}
                                          checked={checkedItems[item[header]] || false}
                                      />
                                      <span>{`${item[header]}`}</span>
                                  </>
                              )}
                              {!isMobile && <span>{`${item[header]}`}</span>}
                          </div>
                          {!isMobile && (
                              <div className={styles.infoIconContainer}>
                                  <FontAwesomeIcon
                                      data-testid="hover-icon"
                                      onMouseOver={(e) => hoverFunction(e, item[header])}
                                      onMouseOut={() => setShowInfoBox(false)}
                                      className={styles.infoCircle}
                                      icon={faInfoCircle}
                                  />
                                  {hoverCondition(item[header])}
                              </div>
                          )}
                      </div>
                  );
              } else {
                  return (
                      <div
                          className={`${!isMobile && styles.cell} ${
                              isMobile && !expandedRows[index] && styles.accordion
                          } ${isMobile && expandedRows[index] === index && styles.cell}`}
                          key={headerIndex}
                      >
                          {!isMobile && `${item[header]}`}
                      </div>
                  );
              }
          })}
          {isMobile && (
              <div
                  className={`${!isMobile && styles.cell} ${
                      isMobile && !expandedRows[index] && styles.accordion
                  } ${isMobile && expandedRows[index] === index && styles.cell} ${
                      styles.mobileRowContainer
                  }`}
                  onClick={() => {
                    const name = t('Name');
                    if ((typeof item[name] === 'string' || item[name] === undefined) && typeof item.Id === 'number') {
                      handleCheckboxChange(
                        !(checkedItems[item[name]] || false),
                        item[name] as string | undefined,
                        item.Id
                      )
                    }
                  }
                  }
              >
                  {translatedHeaders.slice(1, 3).map((mobileHeader, index) => (
                      <div className={styles.mobileColumn} key={index}>
                          <span>{`${mobileHeader}: ${item[mobileHeader]}`}</span>
                      </div>
                  ))}
                  <div className={styles.mobileColumn}>
                      {translatedHeaders.slice(3).map((mobileHeader, index) => (
                          <div className={styles.item} key={index}>
                              <span>{`${mobileHeader}: ${item[mobileHeader]}`}</span>
                          </div>
                      ))}
                  </div>
              </div>
          )}
      </div>
  );
};

export default TableRow;
