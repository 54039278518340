import React from 'react';
import './DeleteRow.scss';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { connect } from 'react-redux';
import Popup from '../Popup/Popup';
import styles from './DeleteRow.scss'

/**
 * Simple deletion row for forms, label on the left, button on the right
 * Takes 3 props:
 *  - labelText
 *  - confirmationText
 *  - onClick
 *  - deletePopup
 */

class DeleteRow extends React.Component {
    state={
        isOpen: false
    }
    render() {
        // translation method with fallback
        const t = this.props.t || (k => k);
        const { theme, btnText, className, deletePopup, labelText, confirmationText, onClick, disabled } = this.props;

        let btnProps = {};
        if (className) {
            btnProps[className] = className;
        }

        return (
            <>
                {this.state.isOpen &&
                    <Popup
                        size="auto"
                        blockContent={true}
                        onCancel={() => this.setState({
                            isOpen: false
                        })}
                        okDisabled={true}
                        onRemove={onClick}
                    >
                        <div className={styles.removeGroupContainer}>
                            <h3>{labelText}</h3>
                            <p>
                                {confirmationText}
                            </p>
                        </div>
                    </Popup>
                }
                <div 
                    className={classNames(
                        'delete-row',
                        theme.textDanger,
                        theme.borderDanger
                    )}
                >
                    <span>{labelText}</span>
                    <Button
                        type={className === undefined && 'danger'}
                        disabled={disabled}
                        data-testid="delete-row-group-delete"
                        onClick={() => {
                            if(deletePopup){
                                this.setState({
                                    isOpen: true
                                })
                            }else if(window.confirm(confirmationText)){
                                onClick()
                            };
                        }}
                        {...btnProps}
                    >
                        {btnText || t('Delete')}
                    </Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(DeleteRow));
