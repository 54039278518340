import APISettings from 'src/API/APISettings';
import themes from 'src/assets/themes/themes';
import { USER_SETTINGS } from 'src/consts';
import { GET_THEME, UPDATE_THEME } from '../types/actionTypes.theme';

import _ from 'lodash';
import { GET_ACCOUNT_SETTINGS, UPDATE_ACCOUNT_SETTINGS } from '../types/actionTypes.accountSettings';

export const updateTheme = data =>
({ type: UPDATE_THEME
 , data: data
});

export const updateAccountSettings = (data, updateBE) => {
    data.key = USER_SETTINGS.GENERAL_KEYS.ACCOUNT_SETTINGS;
    if (updateBE) {
        const store = window.rxStore.getState();
        if(store.accountSettings.data.id !== undefined){
            // if one item already exists, update existing item in 'general' settings
            APISettings.putItem(
                'general',
                store.accountSettings.data.id,
                data,
            )
        }
        else {
            // create new item in general settings
            APISettings.postItem('general', data);
        }
    }
    return({ type: UPDATE_ACCOUNT_SETTINGS
        , data: data
    })
};

export const getAccountSettings = data =>{
    return async(dispatch) => {
        const res = await APISettings.getItems('general')
        let settings = _.find(res?.data, {key: USER_SETTINGS.GENERAL_KEYS.ACCOUNT_SETTINGS});

        // ensure to keep set themes working after data struct change of 'general' userSettings (now requiring 'key'='accountSettings'):
        if(settings === undefined) { settings = res?.data?.find((e)=>e.themeName !== undefined) }

        const theme = settings?.themeName;
        if(themes[theme]){  // save theme to redux - TODO: refactoring possible: we also have theme stored in accountSettings now
            dispatch({
                type: GET_THEME
                , data: themes[theme]
            })
        }
        dispatch({  // save account settings to redux
            type: GET_ACCOUNT_SETTINGS
            , data: settings
        })
        return settings;
    }
}

// let theme;
// async function getTheme(){

// }