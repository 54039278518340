import React from 'react';
import SearchHeader from './SearchHeader/SearchHeader';
import SearchSettings from './SearchSettings/SearchSettings';
import SearchResults from './SearchResults/SearchResults';
import styles from './Search.module.scss';
import APISearch from '../../API/APISearch';
import APISettings from '../../API/APISettings';
import APIChannelGroups from '../../API/APIChannelGroups';
import APIChannels from '../../API/APIChannels';
import _ from 'lodash';
import Profile from '../common/DetailViews/Profile/Profile';
import ItemSelection from '../common/basicElements/ItemSelection/ItemSelection';
import { dateToString, stringToDate } from '../common/helpers';
import SearchHistoryContent from '../common/History/SearchHistoryContent';
import APIQueryVisualization from '../../API/APIQueryVisualization';
import Select from 'react-select';
import formStyles from '../common/basicStyles/forms.module.scss';
import { withTranslation } from 'react-i18next';
import FormFooter from '../common/basicElements/FormFooter/FormFooter';
import detailLayout from '../common/basicStyles/detailLayout.module.scss';
import APIProfileGroups from 'src/API/APIProfileGroups';
import LoadingSpinner from '../common/basicElements/LoadingSpinner/LoadingSpinner';
import TableContainer from '../ChannelGroups/containers/TableContainer/TableContainer';
import { connect } from 'react-redux';
import TableHeading from '../ChannelGroups/components/TableHeading/TableHeading';
import LabeledSelect from '../common/basicElements/LabeledSelect/LabeledSelect';

/* TODOs:
    - remove APIQueryVisualization.get and let <QueryVisualization> component fetch data (use prop 'query' instead of 'data') as already done in Profile.js
    - refactor to simplify code / increase maintainability
*/

class Search extends React.Component {
    constructor(props) {
        super(props);
        let d = new Date();
        d.setDate(d.getDate() - 28);
        this.state = {
            settings: {
                dateRange: props.dateRange ||[d, new Date()],
                lastXNumber: { value: 29, label: 29 },
                lastXUnit: { value: 'days', label: 'days' },
                switches: {
                    byDate: true
                },
                rankBy: 'relevance',
                query: props.query || undefined,
                channelGroup: props.channelGroup || undefined,
                timeAccordionExpanded: 'date', // name of option that is expanded - Warning: dont change state here, accordion component handles state
                scopeAccordionExpanded: props.channelGroup ? 'channelgroup' : 'channels',
                selectedChannels: [],
                pageNo: undefined,
                language: props.language || undefined,
            },
            cards: [],
            totalFound: undefined,
            facets: {},
            history: [],
            channels: [],
            channelGroups: [],
            showChannelSelection: false,
            showProfileView: false,
            searchInput: undefined, // ref to text input field,
            queryVisualization: undefined,
            isLoading: false,
            querySearched: false,
            profileChannelGroup: undefined,         // used for creating profile out of query
            channelGroupLanguages: undefined,       // used for creating profile out of query
            profileChannelGroupSelect: undefined,   // used for creating profile out of query
            profileProfileGroupSelect: undefined,   // used for creating profile out of query
            showTree: true,
            resultsDisabled: false,
            profileGroups: undefined,    // used for creating profile out of query
            loadingChannels: true,
            isFacetSearch: false,
        };
        this.historySettingName = 'searchHistory';
        this.ref = React.createRef();

        this.calls = [];

        this.onHistoryDelete = this.onHistoryDelete.bind(this);
        this.onChannelSelection = this.onChannelSelection.bind(this);
        this.onFacetClick = this.onFacetClick.bind(this);
        this.onSettingsSelectChange = this.onSettingsSelectChange.bind(this);
        this.onSettingsDateRangeChange = this.onSettingsDateRangeChange.bind(this);
        this.onQueryChange = this.onQueryChange.bind(this);
        this.search = this.search.bind(this);
        this.onAddProfileClick = this.onAddProfileClick.bind(this);
        this.onTimeAccordionChange = this.onTimeAccordionChange.bind(this);
        this.onScopeAccordionChange = this.onScopeAccordionChange.bind(this);
        this.loadMoreChannels = this.loadMoreChannels.bind(this);
        this.onChannelSelectionChanged = this.onChannelSelectionChanged.bind(this);
        this.clearFacets = this.clearFacets.bind(this);
        this.onHistoryRowClick = this.onHistoryRowClick.bind(this);
    }

    async componentDidMount() {
        if (this.searchInput) {
            this.searchInput.focus();
        }
        
        let history;
        if (!this.props.historyDisabled) { history = (await APISettings.getItems(this.historySettingName)).data; }
        let channelGroups;
        if (this.props.scopeDisabled && !this.props.showAddProfile) {
            channelGroups = [];
        } else {
            channelGroups = (await APIChannelGroups.get()).data;
            if (Array.isArray(channelGroups)) {
                channelGroups = channelGroups.filter(cg => cg.channels.length > 0);
            }
        }
        
        if (!channelGroups || channelGroups.status) return;

        this.loadMoreChannels();

        if (this.props.searchOnMount) { this.search(false); }
        
        this.setState({
            history : !history || history.status ? [] : history,
            channelGroups            
        });        
    }

    componentDidUpdate(prevProps, prevState) {

        // add channel icons to results (in case channels call backend response came after fetching results)
        if (
            !_.isEqual(prevState.channels, this.state.channels)
            || !_.isEqual(prevState.cards, this.state.cards)
        ) {
            let shouldUpdate = false;
            let cards = this.state.cards.map((card) => {
                if (!card.channelIcon) {
                    const c = _.find(this.state.channels, { id: parseInt(card.channelId) });
                    if(c){
                        card.channelIcon = c.icon;
                        shouldUpdate = true;
                    }
                }
                return card;
            })
            if(shouldUpdate){
                this.setState({ cards });
            }
        }
    }

    async onHistoryDelete(index) {
        const itemId = this.state.history[index].id;
        this.setState((state) => {
            state.history.splice(index, 1);
            return { history: state.history };
        }, async () => { await APISettings.deleteItem(this.historySettingName, itemId);});
    }

    onChannelSelection() {        
        this.setState((s)=>({showChannelSelection:!s.showChannelSelection}));
    }

    onFacetClick(type, facet, index) {
        let prop, compare;
        switch (type) {
            case 'byDate':
                prop = 'date';
                compare = (o) => o[prop] === facet;
                break;
            case 'byChannel':
                prop = 'channel';
                compare = (o) => o.id === facet;
                break;
            default: break;
        }

        let f;
        this.setState((s) => {
            f = _.find(s.facets[type], compare);
            const selected = f.selected;
            if (type === 'byDate') {
                _.map(s.facets[type], (o) => { o.selected = undefined; })
            }
            f.selected = !selected;
            return { facets: {...s.facets} };
        }, () => {
            let facetSelected = {};
            facetSelected.date = _.some(this.state.facets['byDate'], (e)=>e.selected);
            facetSelected.channel = _.some(this.state.facets['byChannel'], (e) => e.selected);
            if (facetSelected.date || facetSelected.channel) {
                this.search(true, undefined, undefined, type, f.selected);
            } else {
                this.search();
            }
        });
    }

    clearFacets() {
        this.setState({ facets: [] });
    }

    onSettingsSelectChange(val, option) {
        this.setState((s) => {
            s.settings[option] = val;
            return {
                settings: s.settings,
            };
        });
    }

    onSettingsDateRangeChange(dateRange) {
        this.setState(state => {
            state.settings.dateRange = dateRange;
            return { settings: state.settings };
        });
    }

    onQueryChange(query) {
        this.setState((s) => {
            s.settings.query = query;
            return {
                settings: s.settings,
                queryIssue: undefined,
                querySearched: false,
            };
        });
        this.updateTree(query);
    }

    updateTree = _.debounce(async (query) => {

        if (!query || query.length === 0) {
            // empty query - dont send network request, reset state variables
            this.setState({
                queryVisualization: undefined,
                queryIssue: undefined,
            });
            return;
        }

        const res = await APIQueryVisualization.get(query, [90013]);
        let queryTree;
        if(res && res.rc === 0){
            queryTree = res.tree;
        } else {
            if(res){
                switch(res.rc) {
                    case 90013: 
                        this.setState({
                            queryIssue: this.props.t('The query syntax is invalid. Please check the query.'),
                        });
                    break;
                    default: break;
                }
            }
            this.setState({queryVisualization: undefined})
            return;
        }
        if (!res || res.status) return;

        this.setState({
            queryVisualization: queryTree,
            queryIssue: undefined,
        });
    }, 500);

    settingsHistoryConverter(settings) {    // convert search settings from frontend to save them to backend
        let s = settings;        
        let item = {
            query: s.query,
            rankBy: s.rankBy
        };
        if(settings.language) item.language = settings.language;
        switch (s.timeAccordionExpanded) {
            case 'date': item.dateFrom = dateToString(s.dateRange[0]); item.dateTo = dateToString(s.dateRange[1]); break;
            case 'lastx': item.lastXNumber = s.lastXNumber.value; item.lastXUnit = s.lastXUnit.value; break;
            default: break;
        }
        switch (s.scopeAccordionExpanded) {
            case 'channels': if (s.selectedChannels?.length > 0) { item.channelIds = s.selectedChannels }; break;
            case 'channelgroup': item.channelGroupId = s.channelGroup?.value; break;
            default: break;
        }        
        return item;
    }

    historySettingsConverter(historyItem) { // convert history backend entries to process them in frontend
        let i = historyItem;        
        
        let settings = {};
        settings.query = i.query;
        settings.rankBy = i.rankBy;
        settings.lastXNumber = { value: i.lastXNumber || this.state.settings.lastXNumber.value, label: i.lastXNumber || this.state.settings.lastXNumber.value };
        settings.lastXUnit = { value: i.lastXUnit  || this.state.settings.lastXUnit.value , label: i.lastXUnit  || this.state.settings.lastXUnit.value };
        
        if (i.channelGroupId) {         
            let tmp = _.find(this.state.channelGroups, function (c) { return i.channelGroupId === c.id; });
            i.channelGroup = { label: tmp.title, value: tmp.id };        
            delete i.channelGroupId;
            settings.scopeAccordionExpanded = 'channelgroup';
            settings.channelGroup = i.channelGroup;
        } else {
            settings.scopeAccordionExpanded = 'channels';
            
            settings.selectedChannels = i.channelIds;
        }
        if (i.dateFrom) {
            settings.timeAccordionExpanded = 'date';
            settings.dateRange = [stringToDate(i.dateFrom), stringToDate(i.dateTo)];
        } else {
            settings.dateRange = this.state.settings.dateRange;
            settings.timeAccordionExpanded = 'lastx';         
        }
        if (!settings.selectedChannels) settings.selectedChannels = [];        
        return settings;
    }

    settingsSearchConverter(settings) { // converts search settings of this component to expected parameters for backend of search
        settings = this.settingsHistoryConverter(settings);
        if (settings.lastXNumber && settings.lastXUnit) {
            settings.last = settings.lastXNumber + settings.lastXUnit;
            delete settings.lastXNumber;
            delete settings.lastXUnit;
        }
        return settings;
    }

    async saveHistory() { // add history entry + save to backend
        let item = this.settingsHistoryConverter(this.state.settings);
        if (!this.historyExists(item)) {
            const response = (await APISettings.postItem(this.historySettingName, item)).data;
            item.id = response.id;
            this.setState((s) => {
                s.history.push(item);
                return {history: s.history};
            });
        }
    }

    historyExists(newItem) {    // returns true if item is already contained in history, false otherwise    // TODO: move this method to history component ?
        newItem = _.omitBy(newItem, _.isUndefined);
        delete newItem.id;
        const currentHistory = _.cloneDeep(this.state.history);
        return _.find(currentHistory, o => { delete o.id; return _.isEqual(_.omitBy(o, _.isUndefined), newItem)}) === undefined ? false : true;
    }

    async search(isFacetSearch, searchOptions, keepResults, updatedFacetType, facetValue) {
        this.calls.map((c) => {
            c.abort();
        });
        this.calls = [];
        const s = this.state.settings;
        if (!searchOptions && (s.query === undefined || s.query === '')) {
            return;
        }
        if (this.searchInput) { this.searchInput.blur(); }
        
        // let pageNo = this.state.pageNo !== undefined ? this.state.pageNo : 0;
        let pageNo = keepResults ? this.state.pageNo + 1 : 1;
        let updatedState = {
            isLoading: true,
            querySearched: true,
            pageNo
        };
        if(keepResults !== true){ updatedState.cards = []; }

        this.setState(updatedState);
        if (!searchOptions) {
            searchOptions = this.settingsSearchConverter(s);
            
            if (isFacetSearch) {
                const date = _.find(this.state.facets.byDate, function (o) { return o.selected === true; });
                let channels = [];
                _.map(this.state.facets.byChannel, function (o) { if (o.selected) channels.push(o.id); });

                if (channels.length === 0) channels = _.cloneDeep(this.state.settings.selectedChannels);
                if (channels) searchOptions.channelIds = channels;
                if (date) {
                    searchOptions.dateFrom = searchOptions.dateTo = date.date;
                    if (searchOptions.last) {
                        delete searchOptions.last;
                    }
                }
            }         
        }

        searchOptions.pageNo = pageNo;
        let abortCtrl = new AbortController();
        this.calls.push(abortCtrl);
        let search = (await APISearch.getLegacy(searchOptions, this.state.channels, [90013, 1709], abortCtrl.signal));
        if (search && search.status === 0) {
            return;
        }
        const hasMore = search.results && search.results.length > 0 ? true : false;
        let stateUpdate = {
            hasMore,
            isLoading: false,
            showChannelSelection: undefined,
        }
        if (!search || search.status || (search.rc !== undefined && search.rc !== 0)){
            if(!search) return;
            let queryIssue;
            switch(search.rc){
                case 90013:
                    queryIssue = this.props.t('The query syntax is invalid. Please check the query before trying again.');
                    break;
                case 1709:
                    queryIssue = this.props.t('query_char_invalid');
                    break;
                default: break;
            }
            this.setState({
                ...stateUpdate,
                isLoading: false,
                queryIssue: queryIssue,
            });
            return;
        }
        
        if(keepResults !== true){
            if(!searchOptions.language){
                this.saveHistory();
            }
        } else{
            search.results = this.state.cards.concat(search.results);
        }

        let facets = _.cloneDeep(this.state.facets);
        if (isFacetSearch) {
            if (updatedFacetType === 'byChannel'
                // || (updatedFacetType === 'byDate' && !facetValue)
            ) {
                facets.byDate.map((facetOld,index) => {
                    if (facetOld.selected) {
                        let newIdx = _.findIndex(search.facets.byDate, { date: facetOld.date })

                        // add existing selection to received facets
                        if (newIdx !== -1) search.facets.byDate[newIdx].selected = true;
                    }
                })

                // update date facets
                facets.bt = search.facets.bt;
                facets.byDate = search.facets.byDate;
            }
            if (updatedFacetType === 'byDate'
                // || (updatedFacetType === 'byChannel' && !facetValue)
            ) {
                facets.byChannel.map((facetOld,index) => {
                    if (facetOld.selected) {
                        let c = _.find(search.facets.byChannel, { id: facetOld.id })

                        // add existing selection to received facets
                        if (c) c.selected = true;
                    }
                })

                // update channel facets
                facets.chnid = search.facets.chnid;
                facets.byChannel = search.facets.byChannel;
            }
        } else {
            facets = search.facets;
        }

        this.setState({
            ...stateUpdate,
            cards: search.results,
            totalFound: search.totalFound,
            facets: facets,
            queryIssue: undefined,
            isFacetSearch: isFacetSearch,
        });
    }

    async onAddProfileClick() {
        let stateUpdated = {};
        if(!this.state.profileGroups){
            stateUpdated.profileGroups = (await APIProfileGroups.get()).data;
        }
        const languages = (await APIChannelGroups.languages(this.state.settings?.channelGroup?.value || 0))?.data;
        stateUpdated.showProfileView = true;

        // reset 'add profile'-view dropdowns
        stateUpdated.channelGroupLanguages = languages;
        stateUpdated.profileChannelGroupSelect = this.state.settings.channelGroup;
        stateUpdated.profileProfileGroupSelect = undefined;
        stateUpdated.profileQueryLang = undefined;

        this.setState(stateUpdated);
    };

    onTimeAccordionChange(expandedIndex) {   // TODO maybe: improve this. current solution is not optimal because state exists here but can only be modified by child component.                
        this.setState((state) => {
            state.settings.timeAccordionExpanded = parseInt(expandedIndex[0]);
            return { settings: state.settings };
        });
    }

    onScopeAccordionChange(expandedIndex) {
        this.setState((state) => {
            state.settings.scopeAccordionExpanded = parseInt(expandedIndex[0]);
            return { settings: state.settings };
        });
    }

    mapChannels(apiChannels) {
        return _.map(apiChannels, c => ({
            id: c.id,
            icon: c.icon,
            mediaType: c.type,
            text: c.name,
            secondaryText: c.country,
        }));        
    }

    async loadMoreChannels() {
        this.setState({loadingChannels: true});
        let channels = (await APIChannels.get(null, this.state.channels.length, 10)).data;
        if (!channels || channels.status) return;

        channels = this.mapChannels(channels);                
        channels = _.concat(this.state.channels, channels);        
        this.setState({channels, loadingChannels: false});
    }

    onChannelSelectionChanged(selected) {        
        this.setState((state) => {
            state.settings.selectedChannels = selected;
            return { settings: state.settings };
        })        
    }

    convertHistory() {  // get api response ready to be rendered by history component
        const channels = this.state.channels;
        const channelGroups = this.state.channelGroups;
        return _.map(this.state.history, (o) => {
            return {
                textLeft: o.query,
                textMiddle: undefined,
                textRight: undefined,
                content: <SearchHistoryContent data={o} channels={channels} channelGroups={channelGroups} />
            }
         });
    }

    onHistoryRowClick(historyItemIndex) {
        const i = _.cloneDeep(this.state.history[historyItemIndex]);
        let settings = this.historySettingsConverter(i);        
        this.setState({ settings: settings }, this.search);
    }

    renderProfileView() {
        let settings = this.state.settings;
        return(
            <Profile
                sharedBy={null}
                update={false}
                onGoBack={() => {
                    this.setState({
                        showProfileView: undefined,
                        profileChannelGroup: undefined,
                        profileChannelGroupSelect: undefined
                    })
                }}
                prefill={{
                    profile: {
                        channelGroupId: this.state.profileChannelGroup?.value,
                        profileGroupId: this.state.profileProfileGroupSelect.value,
                    },
                    init: async (comp) => {
                        comp.onStandardQueryChange({ target: {value:settings.query} }, this.state.profileQueryLang?.value);
                    }
                }}
            />
        )
    }

    renderAddProfileInfo() {
        const { t } = this.props;
        const profileGroupOptions = _.map(this.state.profileGroups, (g) => ({ label: g.title, value: g.id }));
        const channelGroups = _.map(this.state.channelGroups, (c) => ({ label: c.title, value: c.id }));
        const staticChannelGroupOptions = [{
            label: this.props.t('ALL'),
            value: null,
        }];
        const channelGroupOptions = staticChannelGroupOptions.concat(channelGroups);
        const languages = _.map(this.state.channelGroupLanguages, (g) => ({ label: t(g), value: g }));

        const selected = {
            channelGroup: this.state.profileChannelGroupSelect || channelGroupOptions[0],
            profileGroup: this.state.profileProfileGroupSelect,
            language: this.state.profileQueryLang,
        };
        return (
            <div className={detailLayout.wrapper}>
                <article className={detailLayout.view}>
                <h3>{t('Add Profile')}</h3>
                {t('provide_info_to_create_profile')}:
                {!this.state.settings.channelGroup && ( /* let user only select channel group if not already selected in search settings */
                    <LabeledSelect
                        isRequired
                        label={t('Channel Group')}
                        placeholder={t("Select Channel Group")}
                        options={channelGroupOptions}
                        value={selected.channelGroup}
                            onChange={async (val) => {
                                const languages = (await APIChannelGroups.languages(val.value))?.data;
                                let stateUpdate = {
                                    profileChannelGroupSelect: val,
                                    channelGroupLanguages: languages,
                                };
                                if(!_.includes(languages,selected.language?.value)){
                                    stateUpdate.profileQueryLang = undefined;
                                }
                                this.setState((s)=> ({...s, ...stateUpdate}) );
                            }}
                        className={formStyles.select}
                        styles={{ container: (base) => ( {...base, zIndex: 9002} )}}
                    />
                )}
                    <LabeledSelect
                        placeholder={t("select_language")}
                        isRequired
                        label={t('Language')}
                        options={languages}
                        onChange={(val) => { this.setState({ profileQueryLang: val }) }}
                        value={selected.language}
                        className={formStyles.select}
                        styles={{ container: (base) => ( {...base, zIndex: 9001} )}}
                    />
                    <LabeledSelect
                        isRequired
                        label={t('Profile Group')}
                        placeholder={t('Select Profile Group')}
                        options={profileGroupOptions}
                        value={selected.profileGroup}
                        onChange={(val) => { this.setState({profileProfileGroupSelect:val}) }}
                        className={formStyles.select}
                    />
                <FormFooter
                    onGoBack={()=>{ this.setState({
                        showProfileView:false,
                        profileChannelGroupSelect: undefined,
                        profileProfileGroupSelect: undefined,
                    }) }}
                    onAction={() => {this.setState({
                        profileChannelGroup:selected.channelGroup,
                    })}}
                        actionDisabled={(!selected.channelGroup || !selected.profileGroup)}
                    actionLabel={t('Next')}
                />
                </article>
            </div>
        )
    }

    render() {
        const showProfileView = this.state.showProfileView;
        const history = this.convertHistory();
        const s = this.state;
        const {t} = this.props;

        // if(s.loadingChannels) return <LoadingSpinner/>;
        return (
            showProfileView ?
                !s.profileChannelGroup ?
                    this.renderAddProfileInfo()
                :
                    this.renderProfileView()
            :
                <div className={styles.wrapper} style={this.props.wrapperStyle}>
                    <SearchHeader
                        showAddProfile={this.props.showAddProfile}
                        inputRef={(c) => { this.searchInput = c }}
                        onQueryChange={this.onQueryChange}
                        search={this.search}
                        onButtonClick={this.onAddProfileClick}
                        query={this.state.settings.query}
                        queryInvalid={this.state.queryIssue}
                        onInputFocus={()=>this.setState({showTree: true, resultsDisabled: true})}
                        onInputBlur={()=>this.setState({showTree: false, resultsDisabled: false})}
                        buttonDisabled={!this.state.settings.query}
                        buttonTitle={
                            (!this.state.settings.query) ? 
                                t('Please type something into the search field first (to create a profile from it)')
                            : null
                        }
                    />
                    <div className={styles.container}>
                        <SearchSettings                            
                            settings={this.state.settings}
                            history={history}
                            channelGroups={this.state.channelGroups}
                            onHistoryDelete={this.onHistoryDelete}
                            onChannelSelection={this.onChannelSelection}
                            facets={this.state.facets}
                            onFacetClick={this.onFacetClick}
                            onSelectChange={this.onSettingsSelectChange}
                            onDateRangeChange={this.onSettingsDateRangeChange}
                            search={this.search}
                            query={this.state.settings.query}
                            onTimeAccordionChange={this.onTimeAccordionChange}
                            onScopeAccordionChange={this.onScopeAccordionChange}
                            channels={this.state.channels}
                            selectedChannels={this.state.settings.selectedChannels}
                            onHistoryRowClick={this.onHistoryRowClick}
                            scopeDisabled={this.props.scopeDisabled}
                            historyDisabled={this.props.historyDisabled}
                        />
                        {this.state.showChannelSelection ?
                            <div className={styles.checklistsWrapper}>
                                {/* <section className={styles.checklistWrapper}>
                                    <ItemSelection
                                        items={this.state.channels}
                                        loadMore={this.loadMoreChannels}
                                        selected={this.state.settings.selectedChannels}
                                        onSelectionChange={this.onChannelSelectionChanged}
                                    />
                                </section>  */}
                                <TableContainer 
                                historyChannels={this.state.settings} 
                                onSelectionChange={this.onChannelSelectionChanged} 
                                isChannelGroupsWidget={false}
                                onHistoryRowClick={this.onHistoryRowClick}
                                />                               
                                {/* TODO: discuss if we want to be able to select multiple channel groups */}
                                {/* <section className={styles.checklistWrapper}>
                                    <ItemSelection
                                        items={this.state.channels}
                                        loadMore={this.loadMoreChannels}
                                        selected={this.state.settings.selectedChannels}
                                        onSelectionChange={this.onChannelSelectionChanged}
                                    />
                                </section> */}
                            </div>
                            :
                            <SearchResults
                                search={()=>this.search(this.state.isFacetSearch,undefined,true)}
                                totalFound={this.state.totalFound}
                                hasMore={this.state.hasMore}
                                cards={this.state.cards}
                                queryVisualization={this.state.queryVisualization}
                                showTree={this.state.showTree}
                                isLoading={this.state.isLoading}
                                querySearched={this.state.querySearched}
                                resultsDisabled={this.state.resultsDisabled}
                                msg={
                                    this.state.queryIssue ?
                                        (
                                            <span style={{ color: 'red' }}>
                                                {this.state.queryIssue}
                                            </span>
                                        )
                                    : undefined
                                }
                            />}
                        
                    </div>
            </div>

        );
    }
}


const mapStateToProps = state => {
    return{
        selectedReduxChannels: state.channelGroupsWidgetReducer.selectedChannels
    }
}
export default connect(mapStateToProps)(withTranslation()(Search));
