import { getUsername } from 'src/Widgets/common/helpers';
import GenericCalls from './genericCalls';

class APITelemetry {
    static enabled = true;
    // to disable telemetry in dev env: !['development'].includes(process.env.REACT_APP_ENV);
    static disabled_msg = 'telemetry disabled';

    static async sendIfEnabled(enabled, disabled_msg, call, data) {
        return enabled
            ? await call()
            : data ? console.log('telemtry', data) : null;
    }

    static enhanceData(data) {
        const username = getUsername();
        return {
            ...data,
            username,
            commit: process.env ? process.env.REACT_APP_VERSION : undefined,
        };
    }

    static async post(data) {
        return await this.sendIfEnabled(
            this.enabled,
            this.disabled_msg,
            () => GenericCalls.post('/api/telemetry/', this.enhanceData(data), { responseType: 'text', returnResponse: true }, true, false),
            data
        )
    }

    static async postConsoleError(data) {
        this.sendIfEnabled(
            this.enabled,
            this.disabled_msg,
            () => GenericCalls.post('/api/telemetry/console/error', this.enhanceData(data), { responseType: 'text', returnResponse: true }, true, false)
        )
    }

    static async postConsoleWarning(msgString) {
        this.sendIfEnabled(
            this.enabled,
            this.disabled_msg,
            () => GenericCalls.post('/api/telemetry/console/warning', this.enhanceData({ msg: msgString }), { responseType: 'text', returnResponse: true }, true, false)
        )
    }
}

export default APITelemetry;
