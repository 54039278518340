import React, { Component } from 'react';
import _ from 'lodash';
import Profile from '../common/DetailViews/Profile/Profile';
import GroupCardsContainer from '../common/GroupCards/GroupCardsContainer';

import GroupEdit from '../common/DetailViews/GroupEdit/GroupEdit';
import { withTranslation } from 'react-i18next';
//import WidgetSettings from '../common/WidgetSettings/WidgetSettings';
import { connect } from 'react-redux';

import Popup from '../common/basicElements/Popup/Popup';
import config from 'src/config/config';
import { addProfileGroup, deleteProfileGroup, getProfileGroups, updateProfileGroup } from 'src/redux/actions/actions.profileGroups';

// import { addProfileGroup } from 'src/redux/actions/actions.profileGroups';
//import { downloadFile, dateToString, getLastX } from 'src/Widgets/common/helpers';
//import Button from 'src/Widgets/common/basicElements/Button/Button';
//import DownloadButton from '../common/basicElements/DownloadButton/DownloadButton';

/**
 * props:
 *  - searchTerm: prefilling for the cardfilter
 */
class ProfileGroupCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupMsg: undefined,
        }

        this.onPopupOk = this.onPopupOk.bind(this);
        this.buildProfileGroupState = this.buildProfileGroupState.bind(this);
    }

    componentDidMount() {
        if(this.props.profileGroups.status !== "fetched" && !this.props.profileGroups.isLoading){
            this.props.getProfileGroups();
        }
    }

    // rename a few keys for generic cards
    buildProfileGroupState(apiProfileGroups, showDetailView) {
        return _.map(apiProfileGroups, g => {
            const isV2Group = g.profiles.some(p => p.isSuper === null);
            return {
                id: g.id,
                title: g.title,
                createDisabled: (!config.allowMixedProfileTypesInGroup && isV2Group) || g.sharedBy !== null ? true : undefined,
                editDisabled: g.sharedBy !== null,
                createTitle: !config.allowMixedProfileTypesInGroup && isV2Group
                    ? this.props.t('profile_group_v2_only')
                    : g.sharedBy !== null
                        ? this.props.t('profile_is_shared_info')
                        : undefined,
                items: _.map(g.profiles, p => ({
                    id: p.id,
                    title: p.title,
                    disabled: !p.isEnabled || g.sharedBy !== null,
                    highlighted: p.isSuper,
                    onClick: () => showDetailView(p.id, {sharedBy: g.sharedBy})
                }))
            }
        });
    }

    DetailView = props => {
        return (
            <Profile
                profileId={props.id}
                update={true}
                onGoBack={props.goBack}
                sharedBy={props.data?.sharedBy}
                groupId={props.groupId}
            />
        );
    }

      

    CreateView = props => {
        return (
            <Profile
                groupId={props.groupId}
                update={false}
                onGoBack={props.goBack}
                sharedBy={null}
            />
        );
    }

    EditGroupView = props => (
        <GroupEdit
            groupId={props.groupId}
            onGoBack={props.goBack}
            group={props.group}
            updateGroup={async (id, data) => {
                let res = await this.props.updateProfileGroup(id, data, [90121]);
                switch (res.rc) {
                    case 90121:
                        this.setState({popupMsg: `${this.props.t('pg_could_not_update')} "${data.title}". ${this.props.t('pg_title_exists')}.`})
                        break;
                }
                return res;
            }
            }
            deleteGroup={async (id) => {
                let res = await this.props.deleteProfileGroup(id, [90104]);
                switch (res.rc) {
                    case 90104: 
                        this.setState({popupMsg: `${this.props.t('pg_could_not_delete')}`})
                        break;
                }
                return res;
            }}
        />
    )

    onPopupOk = () => this.setState({ popupMsg: undefined });

    render() {
        const {t, settings,
            // showSettings, 
            widgetId, toggleWidgetSettings, state} = this.props;
        return (
            <>

                {/* <WidgetSettings
                    show={settings && state && state.showSettings}
                    settings={settings}
                    toggleWidgetSettings={toggleWidgetSettings}
                    widgetId={widgetId}
                /> */}

                {this.state.popupMsg &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={this.onPopupOk}
                    >
                        <div style={{ overflow: 'auto' }}>
                            <p>{this.state.popupMsg}</p>
                        </div>
                    </Popup>}

                <GroupCardsContainer
                    itemName={{n:t("Profiles")}}
                    trackingName='profile'
                    buildGroupState={this.buildProfileGroupState}
                    reduxCreateGroup={async(d) => {
                        const res = await this.props.addProfileGroup(d, [90102]);
                        switch (res.rc) {
                            case 90102:
                                this.setState({popupMsg: `${t('pg_could_not_create')} "${d.title}". ${t('pg_title_exists')}.`})
                                break;
                        }
                        return res;
                    }}
                    groups={this.props.profileGroups.data}
                    // getGroupSearch={APIProfileGroups.search}
                    getGroupSearch={(searchTerm, groups) => {
                        let match = groups.filter((g) => {
                            if (g.title.includes(searchTerm)) {
                                return true;
                            } else {
                                return g.items.some((i) => i.title?.includes(searchTerm));
                            }
                        });
                        return { data: match, fetched: false };
                    }}
                    searchTerm={this.props.searchTerm}
                    detailView={this.DetailView}
                     
                    createView={this.CreateView}
                    groupView={this.EditGroupView}
                    // downloadProfileGroups={this.DownloadProfileGroups} 
                />
            </>
            
        );
    }
}

const mapStateToProps = (state) => ({ matomo: state.matomo, profileGroups: state.profileGroups });

export default connect(mapStateToProps, {
    addProfileGroup,
    updateProfileGroup,
    deleteProfileGroup,
    getProfileGroups
})(withTranslation()(ProfileGroupCards));