import { useState, useEffect, ChangeEvent } from 'react';

import EPGFilter from './EPGFilter/EPGFilter';
import EPGContent from './EPGContent/EPGContent';
import History from '../common/History/History';
import {
    areDatesEqual,
    dateToString,
    dateToTimeString,
    timestampToDate
} from '../common/helpers';
import APIMe from '../../API/APIMe';
import APIEpg from '../../API/APIEpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    getEpgHistory,
    postEpgHistoryItem,
    deleteEpgHistoryItem
} from '../../redux/actions/actions.epgHistory';
import APIChannels from 'src/API/APIChannels';
import { EPGChannel } from './EPGFilter/EPGFilter.types';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { EPGHistoryChannel, Filter } from './EPG.types';
import { Channel } from 'src/@types/shared.types';
import style from './EPG.module.scss'
import useEPG from './useEPG';
import { useTranslation } from 'react-i18next';

const EPG = () => {
    const [iFrameUrl, setIFrameUrl] = useState<string>('');
    const [content, setContent] = useState([]);
    const [timezone, setTimezone] = useState('unknown');
    const [history, setHistory] = useState<EPGHistoryChannel[]>([]);
    const [channels, setChannels] = useState([]);
    const [filter, setFilter] = useState<Filter>({
        selectedChannel: null,
        date: new Date(),
        hour: 0,
        minute: 0,
    });
    const [filtersShownMobile, setFiltersShownMobile] = useState(true);
    const [isLoadingEpg, setIsLoadingEpg] = useState(false);

    const dispatch = useAppDispatch();
    const epgHistory = useAppSelector((state) => state.epgHistory);

    const { t } = useTranslation();

    const { createShowMedialUrl } = useEPG();

    /*   const historySettingName = 'epgHistory'; */

    //! program data coming undefined

    const changeIFrameUrl = (iFrameUrl: string) => window.open(iFrameUrl, 'mediaEditor'); //setIFrameUrl(iFrameUrl);

    const convertHistory = (history: EPGHistoryChannel[]) => {

        return history?.map((o) => ({
            textLeft: (
                <>
                    <FontAwesomeIcon icon={faImage} /> {o.channel}
                </>
            ),
            textMiddle: dateToString(timestampToDate(o.timestamp)),
            textRight: o.program
                ? o.program
                : dateToTimeString(timestampToDate(o.timestamp))
        }));
    };

    const changeContent = async (channel: EPGChannel, date: Date) => {
        if (channel === null || date === null) {
            setContent([]);
        } else {
            setIsLoadingEpg(true);
            const response = await APIEpg.getEPG(
                channel.value,
                dateToString(date),
                channel?.label
            );
            const contentData = response.data;

            if (!contentData || contentData.status) return;

            setContent(contentData);
            setIFrameUrl('');
            setIsLoadingEpg(false);
        }
    };

    const searchForContent = async (term: string, from: Date, to: Date) => {
        const response = await APIEpg.getEPGBySearch(
            term,
            dateToString(from),
            dateToString(to),
            filter?.selectedChannel?.value
        );
        const contentData = response.data;

        if (!contentData || contentData.status) return;

        setContent(contentData);
        setIFrameUrl('');
    };

    const onUse = (channelId: number, channelName: string, ts: number, program: any) => {
        const item: EPGHistoryChannel = {
            channelId: channelId,
            channel: channelName,
            timestamp: ts,
            program: program
        };
        saveHistory(item);
    };

    const saveHistory = (item: EPGHistoryChannel) => {
        dispatch(postEpgHistoryItem(item));
    };

    const onHistoryDelete = (index: number) => {
        const itemId = history[index].id;
        dispatch(deleteEpgHistoryItem(itemId));
    };

    const onHistoryRowClick = (clickedRowIndex: number) => {
        const item = history[clickedRowIndex];
    
        const findChannel = (channels: Channel[], channelId: number) => {
            for (const channel of channels) {
                if (channel.id === channelId) {
                    return channel;
                }
            }
            return null;
        };
    
        const channel = findChannel(channels, item.channelId);
        const newFilter = { ...filter };
        if (channel) {
            newFilter.selectedChannel = {
                value: item.channelId,
                label: item.channel,
                mediaType: channel.mediaType
            };
            newFilter.date = timestampToDate(item.timestamp);
            newFilter.hour = newFilter.date.getHours();
            newFilter.minute = newFilter.date.getMinutes();
    
            let newContent: any = [];
            if (
                newFilter?.selectedChannel?.value === filter?.selectedChannel?.value &&
                areDatesEqual(newFilter.date, filter.date)
            ) {
                newContent = content;
            }
    
            setFilter(newFilter);
            setContent(newContent);
    
            if (item) {
                onEpgClick(
                    item.channelId,
                    item.channel,
                    item.timestamp,
                    item.program,
                    channel.mediaType
                );
            } else {
                onMinuteButtonClick(channel.mediaType, true);
            }

            const date = new Date(item.timestamp * 1000) 
            changeContent(newFilter.selectedChannel, date);
        }
    };
    

    const onChannelChange = (selectedChannel: EPGChannel, date: Date) => {
        setFilter((prevFilter) => {
            changeContent(selectedChannel, date);
            const newFilter = { ...prevFilter };
            newFilter.selectedChannel = selectedChannel;
            return newFilter;
        });
    };

    const onDateChange = (date: Date) => {
        setFilter((prevFilter) => {
            if (prevFilter.selectedChannel !== null) {
                changeContent(prevFilter.selectedChannel, date);
            }
            const newFilter = { ...prevFilter };
            newFilter.date = date;
            return newFilter;
        });
    };
    

    const onMinuteChange = (minute:string|undefined) => {
        const inputValue = minute !== undefined ? parseInt(minute, 10) : undefined;

        if (inputValue === undefined || (inputValue >= 0 && inputValue < 60)) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                minute: inputValue
            }));
        }
    };

    const onHourChange = (hour: string|undefined) => {
        const inputValue = hour !== undefined ? parseInt(hour, 10) : undefined;

        if (inputValue === undefined || (inputValue >= 0 && inputValue < 24)) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                hour: inputValue
            }));
        }
    };

    

    const onEpgClick = (channelId: number, channelName: string, startTS: number, title: string, channelMediaType: number) => {
        onUse(channelId, channelName, startTS, title);
        
        const url = createShowMedialUrl(channelMediaType, channelId, startTS, timezone);
        changeIFrameUrl(url);
    };

    const onMinuteButtonClick = (channelMediaType: number, history = true) => {
        const selectedDate = filter.date;
        const storyTS =
            new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                filter.hour,
                filter.minute
            ).getTime() / 1000;
        const channel = filter.selectedChannel;

        if (channel !== undefined && channel?.value !== undefined) {
            const url = createShowMedialUrl(channelMediaType, channel?.value, storyTS, timezone);
            changeIFrameUrl(url);

            if (history) {
               if(channel !== null){
                 onUse(channel.value, channel.label, storyTS, '');
               }
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const userResponse = await APIMe.get();
            const channelsResponse = await APIChannels.get();

            const user = userResponse.data;
            const channelsData = channelsResponse.data;

            if (!user || user.status) return;
            dispatch(getEpgHistory());
            setTimezone(user.timezone);
            setChannels(channelsData);
        };

        fetchData();
    }, [getEpgHistory, dispatch]);

    useEffect(() => {
        if (epgHistory !== history) {
            setHistory(epgHistory);
        }
    }, [epgHistory]);

    const convertedHistory = convertHistory(history);
    const selectedChannel = filter ? filter.selectedChannel : null;

    return (
        <div style={{height: '100%'}}>
            <div className={style.filtersToggle}>
                <button onClick={()=>setFiltersShownMobile(!filtersShownMobile)}>
                    <div>
                    <div>{t('Filters & History')} {!filtersShownMobile && <>({dateToString(filter.date)}, {selectedChannel?.label})</>}</div>
                    </div>
                    {<div className={[!filtersShownMobile && style.hideMobile].join(' ')}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>}
                </button>
            </div>
            <div className={style.epg}>
            {<div className={[style.filterHistoryWrapper, !filtersShownMobile && style.hideMobile].join(' ')}>
                <EPGFilter
                    changeIFrameUrl={changeIFrameUrl}
                    changeContent={changeContent}
                    searchForContent={searchForContent}
                    timezone={timezone}
                    onUse={onUse}
                    selectedChannel={selectedChannel}
                    onChannelChange={onChannelChange}
                    date={filter.date}
                    onDateChange={onDateChange}
                    minute={filter.minute}
                    onMinuteChange={onMinuteChange}
                    hour={filter.hour}
                    onHourChange={onHourChange}
                    onMinuteButtonClick={() =>
                        {
                            if(selectedChannel !== null){
                                onMinuteButtonClick(selectedChannel.mediaType, true)
                            }
                        }
                    }
                    channels={channels}
                    setFiltersShownMobile={setFiltersShownMobile}
                />
                {convertedHistory.length > 0 && (
                    <div style={{ height: 'calc(100% - 20.5rem)' }}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold', margin:'0.3rem' }}>{t('History')}</label>
                        <History
                            data={convertedHistory}
                            onClick={onHistoryRowClick}
                            onDelete={onHistoryDelete}
                        />
                    </div>
                )}
            </div>}
            <EPGContent
                changeIFrameUrl={changeIFrameUrl}
                iFrameUrl={iFrameUrl}
                content={content}
                // timezone={timezone}
                // onUse={onUse}
                onEpgClick={(...params: Parameters<typeof onEpgClick>) =>
                    {
                        if (selectedChannel) {
                            //@ts-ignore
                            onEpgClick(...params, selectedChannel.mediaType);
                        }
                }}
                isLoading={isLoadingEpg}
                channelSelected={!!selectedChannel}
            />
            </div>
        </div>
    );
};

export default EPG;
